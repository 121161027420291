<!-- <template>
  <div class="map-view">
    <div id="main"></div>
  </div>
</template>

<script>
  import geoJson from '@/assets/china.json'
  // import {travel} from '@/api/api'
  import axios from 'axios'
  // import { getToken} from '../../utils/setToken'
export default {
  data(){
    return{

    }
  },
async  created(){
// travel().then(res=>{
//   if(res.data.status===200){
//     let {points,linesData}=res.data.data
//     console.log(points)
//     this.draw(points,linesData)
//   }
// })

let res= await axios.get('http://www.zjgcpt.cn:9999/gps-web/api/login.jsp?password=554b4e7dcedaaa04a416ef03a48ace98&onlyValidate=&plateColor=&userId=qnzj&loginType=user&loginWay=interface&loginLang=zh_CN')
// .then(res => {

// setToken('id', res.data.sessionId)
// sess=res.data.sessionId

// })

axios.get('http://www.zjgcpt.cn:9999/gps-web/api/get_gps_r.jsp?teamId=&carIds=&sessionId='+res.data.sessionId)
    .then(res=>{
      let points=[]
      let linesData=[]
      res.data.list.forEach(item=>{
        

        points.push({value:[item.lng,item.lat],itemStyle:{color:`rgb(${Math.round(Math.random()*255)},${Math.round(Math.random()*255)},${Math.round(Math.random()*255)})`}})
        linesData.push({coords:[[item.lng,item.lat],[107.496831,26.373042]],lineStyle:{color:`rgb(${Math.round(Math.random()*255)},${Math.round(Math.random()*255)},${Math.round(Math.random()*255)})`}})
        
      })
      
      this.draw(points,linesData)
    })
  },
  methods:{
    draw(points,linesData){
      let myChart = this.$echarts.init(document.getElementById('main'))
    this.$echarts.registerMap('贵州',geoJson)
    let option={
      backgroundColor:'rgb(121,145,209)',
      geo:{
        map:'贵州',
        aspectScale:0.75,
        zoom:1.1,
        itemStyle:{
          normal:{
            areaColor:{
              type:'radial',
              x:0.5,
              y:0.5,
              r:0.8,
              colorStops:[
                {
                  offset:0,
                  color:'#09132c'
                },
                {
                  offset:1,
                  color:'#274d68'
                }
              ],
              globalCoord:true
            },
            shadowColor:'rgb(58,115,192)',
            shadowOffsetX:10,
            shadowOffsetY:10
          }
        }
        // regions:[{
        //   name:'南海诸岛'
        // }]
      },
      series:[
        {
          type:'map',
          label:{
            normal:{
              show:true,
              textStyle:{
                color:'#1DE9B6'
              }
            },
            emphasis:{
              textStyle:{
                color:'rgb(183,185,14)'
              }
            }
          },
          zoom:1.1,
          map:'贵州',
          itemStyle:{
          normal:{
            backgroundColor:'rgb(147,235,248)',
            borderWidth:1,
            areaColor:{
              type:'radial',
              x:0.5,
              y:0.5,
              r:0.8,
              colorStops:[
                {
                  offset:0,
                  color:'rgb(31,54,150)'
                },
                {
                  offset:1,
                  color:'rgb(89,128,142)'
                }
              ],
              globalCoord:true
            }
          },
          emphasis:{
            areaColor:'rgb(46,229,206)',
            borderWidth:0.1
          }
        }
        },
        {
          type:'effectScatter',
          coordinateSystem:'geo',
          showEffectOn:'render',
          symbolSize:10,
          zlevel:1,
          data:points,
          rippleEffect:{
            period:15,
            scale:4,
            brushType:'fill'
          }
        },
        {
          type:'lines',
          zlevel:2,
          effect:{
            show:true,
            period:4,
            symbol:'arrow',
            symbolSize:7,
            trailLength:0.4,

          },
          lineStyle:{
            normal:{
              color:'#1DE9B6',
              width:1,
              opacity:0.1,
              curveness:0.3
            }
          },
          data:linesData       
        }
      ]
    }
    
    myChart.setOption(option)
    // console.log(geoJson)
    myChart.on("click", param => {
        console.log(param)
      });
    }
 
    
  }
}
</script>

<style lang="scss">
  .map-view{
    width:100%;
    #main{
      width: 100%;
      height: 800px;
    }
  }

</style> -->
<template>
<div>
  3434343
  
</div>
</template>

<script>

 
export default {
  data() {
    return {
      
    };
  },
 async mounted () {
  
    },
  methods: {
    
  }
};
</script>
<style  >
  

</style>